import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { convertToI18, formatNumber } from "@/utils/count";
import { wrapGoodCDN, wrapCDN } from "@/utils/cdn";
import { goodsVideoData, productVideoData } from "@home3/homeType";
import { handleToUrl } from "@/utils/sys";
type Props = {
    data: productVideoData[];
};
const Index = (props: Props) => {
    const { t, i18n } = useTranslation("home");
    const { data } = props;
    const threeData: productVideoData[] = (data || []).slice(0, 3);
    const handClick = (suburl: string) => {
        handleToUrl("/mobile/videos/" + suburl);
    };
    return (
        <div className={styles.productItemBox}>
            {(threeData || []).map((item, index) => (
                <div key={index} className={styles.pic}>
                    <div
                        className={styles.item}
                        onClick={() => handClick("promotion")}
                    >
                        <Image
                            lazyBoundary="10px"
                            src={wrapCDN(
                                item?.originCoverPrivatization,
                                162 * 2
                            )}
                            alt=""
                            width={1620}
                            height={2260}
                            objectFit="cover"
                            style={{
                                borderRadius: "12px",
                            }}
                        ></Image>
                        <div className="rounded-[10px] absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.1)]"></div>
                        <div className={styles.info}>
                            {/* <div className={styles.atavarPic}>
                                <Image
                                    src={wrapCDN(
                                        item?.avatarLargerPrivatization,
                                        36 * 2
                                    )}
                                    alt=""
                                    className="rounded-full"
                                    width={360}
                                    height={360}
                                ></Image>
                            </div> */}
                            <div className={styles.digg}>
                                <svg
                                    className="icon"
                                    viewBox="0 0 1024 1024"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    p-id="4865"
                                    width="10"
                                    height="10"
                                >
                                    <path
                                        d="M874.026667 224.32c-98.069333-98.090667-256.384-100.138667-356.906667-4.992l-5.12 4.992-4.693333-4.586667c-100.949333-95.573333-259.264-93.504-357.333334 4.586667-99.989333 99.968-99.989333 262.058667 0 362.026667l331.861334 331.882666a42.666667 42.666667 0 0 0 60.330666 0l331.882667-331.882666 4.565333-4.693334c95.573333-100.949333 93.504-259.242667-4.565333-357.333333z"
                                        p-id="4866"
                                        fill="#FFF"
                                    ></path>
                                </svg>
                                <span className={styles.diggCount}>
                                    {convertToI18(item?.diggCount)}
                                </span>
                            </div>

                            <div className={styles.play}>
                                <span className={styles.playCount}>
                                    {formatNumber(item?.diggIncreCount, true)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default Index;
