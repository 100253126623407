/*
 * @Descripttion: 
 * @version: 
 * @Author: ared
 * @Date: 2022-10-27 14:46:04
 * @LastEditors: ared
 * @LastEditTime: 2022-10-31 16:02:56
 */
// @flow
import * as React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { goodsVideoData, productVideoData } from "@home3/homeType";
import GoodsBox from "./goodsBox";
import ProductBox from "./productBox";
import { handleToMoblieList, handleToUrl } from "@/utils/sys";
import { Carousel } from "antd";

type Props = {
    productData: productVideoData[];
    goodsVideoData: goodsVideoData[];
};

const Index = (props: Props) => {
    const { t, i18n } = useTranslation("home");
    const { productData, goodsVideoData } = props;
    let [curIndex, setCurIndex] = useState(0);
    const handClick = (suburl: string) => {
        // handleToMoblieList("Videos", suburl);
        handleToUrl("/mobile/videos/" + suburl);
    };

    return (
        <div className={`${styles.video}`}>
            <div className={`${styles.container}`}>
                <div className={styles.tap}>
                    <ul>
                        <li>
                            <a
                                style={{
                                    color:
                                        curIndex === 0
                                            ? "#2E384E"
                                            : "rgba(46,56,78,0.4600)",
                                }}
                                onClick={() => setCurIndex(0)}
                            >
                                {t("goodsVideo")}
                                <div
                                    className={styles.bar}
                                    style={{
                                        display: curIndex !== 0 ? "none" : "",
                                    }}
                                ></div>
                            </a>
                        </li>
                        <li>
                            <a
                                style={{
                                    marginLeft: "calc(100vw * (20 / 375))",

                                    color:
                                        curIndex === 0
                                            ? "rgba(46,56,78,0.4600)"
                                            : "#2E384E",
                                }}
                                onClick={() => setCurIndex(1)}
                            >
                                {t("productVideoRank")}
                                <div
                                    className={styles.bar}
                                    style={{
                                        display: curIndex === 0 ? "none" : "",
                                    }}
                                ></div>
                            </a>
                        </li>
                    </ul>
                    <div
                        className={styles.more}
                        onClick={() =>
                            handClick(curIndex === 0 ? "products" : "promotion")
                        }
                    >
                        {t("_more")}
                    </div>
                </div>
                <div className={styles.itemTitle2}>{t("update1")}</div>
                <div className={styles.main}>
                    {curIndex == 0 ? (
                        <GoodsBox data={goodsVideoData} />
                    ) : (
                        <ProductBox data={productData} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Index;
