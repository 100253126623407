// @ts-nocheck
export const convertToI18 = (
    labelValue: number | string,
    needPrefix = false
) => {
    if (labelValue === null || labelValue === undefined) return "--";
    const prefix =
        labelValue > 0 ? (needPrefix ? "+" : "") : labelValue < 0 ? "-" : "";
    // Nine Zeroes for Billions
    const val =
        Math.abs(Number(labelValue)) >= 1.0e9
            ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + "B"
            : // Six Zeroes for Millions
            Math.abs(Number(labelValue)) >= 1.0e6
            ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + "M"
            : // Three Zeroes for Thousands
            Math.abs(Number(labelValue)) >= 1.0e3
            ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + "K"
            : Math.abs(Number(labelValue));

    return prefix + val.toString();
};

//输入一个字符串id 返回随机0-4
export const rangeNumID = (numStr: string) => {
    if (!numStr || numStr === undefined || numStr === null) return 0;
    const isNumber = /^[0-9]+$/.test(numStr);
    if (!isNumber) {
        return 0;
    }
    const numId = parseInt(numStr[numStr.length - 1]);
    const num = numId % 4;
    return num;
};

export const formatNumber = (val: number | undefined, addPrefix = false) => {
    if (val === undefined || val === null || Object.is(val, NaN)) return "-";

    if (addPrefix && +val === 0) return "-";

    const prefix = addPrefix && val > 0 ? "+" : "";

    if (Math.abs(val) >= 10e11) return prefix + (val / 10e11).toFixed(1) + "T";
    if (Math.abs(val) >= 10e8) return prefix + (val / 10e8).toFixed(1) + "B";
    if (Math.abs(val) >= 10e5) return prefix + (val / 10e5).toFixed(1) + "M";
    if (Math.abs(val) >= 10e2) return prefix + (val / 10e2).toFixed(1) + "K";

    return prefix + val.toString();
};
export const getDate = (diffValue: any) => {
    var minute = 60;
    var hour = minute * 60;
    var day = hour * 24;
    var month = day * 30;
    var monthC = diffValue / month;
    var weekC = diffValue / (7 * day);
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    var res = "";
    var nowTime = new Date().getTime(); // 获取当前时间戳
    if (monthC >= 12) {
        var oldTime = nowTime - diffValue; // 获取记录的时间戳
        var date = new Date(oldTime);
        res =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
    } else if (monthC >= 1) {
        res = parseInt(monthC) + "月前";
    } else if (weekC >= 1) {
        res = parseInt(weekC) + "周前";
    } else if (dayC >= 1) {
        res = parseInt(dayC) + "天前";
    } else if (hourC >= 1) {
        res = parseInt(hourC) + "小时前";
    } else if (minC >= 1) {
        res = parseInt(minC) + "分钟前";
    } else {
        res = "刚刚";
    }
    return res;
};
export const countDays = (time: any, t: any) => {
    let dateTime = new Date(time);
    // 如果为null,则格式化当前时间
    if (!dateTime) dateTime = Number(new Date());
    // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
    if (dateTime.toString().length == 10) dateTime *= 1000;
    let timestamp = +new Date(Number(dateTime));

    let timer = (Number(new Date()) - timestamp) / 1000;
    // 如果小于5分钟,则返回"刚刚",其他以此类推
    let tips = "";
    switch (true) {
        case timer < 300:
            tips = "just";
            break;
        case timer >= 300 && timer < 3600:
            tips = parseInt(timer / 60) + ` ${t("minutes_ago")}`;
            break;
        case timer >= 3600 && timer < 86400:
            tips = parseInt(timer / 3600) + ` ${t("hours_ago")}`;
            break;
        case timer >= 86400 && timer < 2592000:
            tips = parseInt(timer / 86400) + ` ${t("days_ago")}`;
            break;
        default:
            if (timer >= 2592000 && timer < 365 * 86400) {
                tips = parseInt(timer / (86400 * 30)) + ` ${t("months_ago")}`;
            } else {
                tips = parseInt(timer / (86400 * 365)) + ` ${t("years_ago")}`;
            }
    }
    return tips;
};

export function formatDuration(val: number) {
    let sec = val;
    let min = 0;
    let hour = 0;
    if (val > 60) {
        min = parseInt(sec / 60);
        sec = parseInt(sec % 60);
        if (min > 60) {
            hour = parseInt(min / 60);
            min = parseInt(min % 60);
        }
    }

    return (min >= 10 ? min : `0${min}`) + ":" + (sec >= 10 ? sec : `0${sec}`);
}

export function formatDigit(val: number) {
    if (!val || Object.is(val, NaN)) {
        return "--";
    }
    return new Intl.NumberFormat({}).format(val);
}
