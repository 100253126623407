import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { convertToI18, formatNumber } from "@/utils/count";
import { wrapGoodCDN, wrapCDN } from "@/utils/cdn";
import { goodsVideoData } from "@home3/homeType";
import { handleToUrl } from "@/utils/sys";
type Props = {
    data: goodsVideoData[];
};
const Index = (props: Props) => {
    const { t, i18n } = useTranslation("home");
    const { data } = props;
    const threeData: goodsVideoData[] = (data || []).slice(0, 3);
    const handClick = (suburl: string) => {
        handleToUrl("/mobile/videos/" + suburl);
    };
    return (
        <div className={styles.goodItemBox}>
            {(threeData || []).map((item, index) => (
                <div key={index} className={styles.pic}>
                    <div
                        className={styles.item}
                        onClick={() => handClick("products")}
                    >
                        <Image
                            lazyBoundary="10px"
                            src={wrapCDN(
                                item?.originCoverPrivatization,
                                162 * 2
                            )}
                            alt=""
                            width={1620}
                            height={2260}
                            objectFit="cover"
                            style={{
                                borderRadius: "12px",
                            }}
                        ></Image>
                        <div className="rounded-[10px] absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.1)]"></div>
                        <div className={styles.top}>
                            <div className={styles.svg}>
                                <svg
                                    className="icon"
                                    viewBox="0 0 1024 1024"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    p-id="4643"
                                >
                                    <path
                                        d="M522.666667 192c214.570667 0 336.853333 145.130667 390.592 231.616a166.378667 166.378667 0 0 1 0 176.768C859.498667 686.869333 737.258667 832 522.666667 832 308.096 832 185.813333 686.869333 132.074667 600.384a166.378667 166.378667 0 0 1 0-176.768C185.834667 337.130667 308.074667 192 522.666667 192z m0 114.56c-114.837333 0-207.914667 91.968-207.914667 205.44 0 113.472 93.077333 205.44 207.914667 205.44 114.773333-0.085333 207.808-92.010667 207.914666-205.44 0-113.472-93.077333-205.44-207.914666-205.44z m0 68.48c76.565333 0 138.624 61.312 138.624 136.96s-62.08 136.96-138.624 136.96c-76.565333 0-138.624-61.312-138.624-136.96s62.08-136.96 138.624-136.96z"
                                        p-id="4644"
                                        fill="#ffffff"
                                    ></path>
                                </svg>
                            </div>
                            <div>{convertToI18(item.playCount)}</div>
                        </div>
                        <div className={styles.bottom}>
                            <div className={styles.atr}>
                                <Image
                                    lazyBoundary="10px"
                                    src={wrapGoodCDN(
                                        (item?.productList && item?.productList[0].imagesPrivatization && item?.productList[0].imagesPrivatization[0]) || '', 36 * 3
                                    )}
                                    alt=""
                                    width={360}
                                    height={360}
                                    objectFit="cover"
                                    className="rounded-[8px] !border-[1px] !border-solid !border-[#ECECEC]"
                                ></Image>
                            </div>
                            <div className="scale-95">
                                <div className="flex">
                                    <div className={styles._countName}>
                                        {t("totalSales") + ":"}
                                    </div>
                                    <div className={styles._count}>
                                        {convertToI18(item?.totalEstimatedSalesVolume)}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className={styles._countName}>
                                        {t("GMV") + ":"}
                                    </div>
                                    <div
                                        className={
                                            styles._count + " !text-[#FF3E5F]"
                                        }
                                    >
                                        {item?.totalEstimatedSalesAmountUsd}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default Index;
