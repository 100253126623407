export const wrapCDN = (id: string, width: number) =>
    process.env.NEXT_PUBLIC_IMG_DOMAIN +
    `/tikmeta/${id}?imageMogr2/auto-orient/thumbnail/${width}x/strip/format/WEBP/quality/75!/ignore-error/1`;

export const countryIcon = (countryCode: string, width = 60) => {
    return (
        process.env.NEXT_PUBLIC_IMG_DOMAIN +
        `/flag/${countryCode}.png?imageMogr2/auto-orient/thumbnail/${width}x/strip/format/WEBP/quality/75!/ignore-error/1`
    );
};

export const wrapGoodCDN = (id: string, width: number) =>
    process.env.NEXT_PUBLIC_IMG_DOMAIN +
    `/product/${id}?imageMogr2/auto-orient/thumbnail/${width}x/strip/format/WEBP/quality/75!/ignore-error/1`;
